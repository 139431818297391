<template>
  <div>
    <b-card class="report-page w-360">
      <h4 class="card-title report-title">
        <!-- {{ $t("ReportsData.ReportsTitle") }} -->
      </h4>
      <div class="report-body">
        <b-row class="m-0">
          <b-col sm="6" class="d-flex align-items-center">
            <div
              class="d-flex align-items-center justify-content-center cursor-pointer custom-btn"
              @click="createReport"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.REPORTS
                })
              "
            >
              <span class="create-report-text text-primary">{{
                $t("ReportsData.CreateReport")
              }}</span
              ><feather-icon
                size="18"
                icon="PlusCircleIcon"
                class="text-primary"
              />
            </div>
          </b-col>
          <b-col sm="6"> <b-img :src="report_img_url" class="w-100" /> </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert, BButton, BRow, BCol, BImg } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BRow,
    BCol,
    BImg
  },
  data() {
    return {
      report_img_url: require("@/assets/images/pages/report-bg.png")
    };
  },
  methods: {
    createReport() {
      this.$router.push({ name: "select-report" });
    }
  }
};
</script>

<style lang="scss">
.dark-layout {
  .report-page .report-title {
    padding: 1.5rem;
    margin-bottom: 0;
    border-bottom: 1px solid #3b4253 !important;
  }
}

.report-page {
  min-height: 70vh;
  .card-body {
    padding: 0;
  }
  .report-body {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
  .create-report-text {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .custom-btn {
    height: 250px;
    width: 250px;
    background-color: var(--rgb-primary-12);
    border-radius: 50%;
    margin: auto;
    box-shadow: 0 4px 24px 0 var(--rgb-primary-12);
    transition: all 0.3s;
    overflow: hidden;
    z-index: 1;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      z-index: -2;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 250px;
      background-color: var(--primary);
      transition: all 0.3s;
      border-radius: 10rem;
      z-index: -1;
    }
    &:hover {
      &:before {
        width: 250px;
      }
    }
  }
  .custom-btn:hover {
    background-color: var(--primary);
    .text-primary {
      color: var(--white) !important;
    }
  }
}
</style>
